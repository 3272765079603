/* eslint-disable no-underscore-dangle */
/**
 * Converts the context to an object of field:values
 * @param ctx {Object} The Vue Instance which called this function
 * @param fields {Array} A list of strings
 * @return {Object} Flattened Object in shape of { field: value, ... }
 */
const _getValueObject = (ctx, fields) => fields.reduce((acc, field) => {
  acc[field] = ctx[field];
  return acc;
}, {});

/**
 * This initialized the errors object based on the fields in the model
 * @param fields {Array} A List of strings
 * @return {Object} An object of field properties with all values initialized to ''
 */
const _getDefaultErrorObject = (fields) => fields.reduce((acc, field) => {
  acc[field] = {
    message: '',
    hasError: false,
  };
  return acc;
}, {});

/**
 * Flattens the errors from Yup into an Object
 * @param errors {Array} List of errors returned from yup
 * @return {Object} Ex: { name: 'Name is required', ... }
 */
const _mapMessagesToFields = (fields, errors) => errors?.reduce((acc, { message, path: field }) => {
  acc[field] = {
    message: message || '',
    hasError: fields.indexOf(field) >= 0 && !!message,
  };
  return acc;
}, {});

/**
 * Gets all errors and maps them to the appropriate fields
 * @param ctx {Object} the Vue Instance which called this function
 * @param fields {Array} A list of strings representing the form's properties
 * @return {Object}}
 */
const mapErrors = (ctx, fields = []) => {
  const errors = _getDefaultErrorObject(fields);
  if (ctx?.lazy) return errors;
  try {
    const valueObject = _getValueObject(ctx, fields);
    ctx.rules.validateSync(valueObject, { abortEarly: false, context: ctx });
  } catch (err) {
    Object.assign(errors, _mapMessagesToFields(fields, err.inner));
  }

  return errors;
};

/**
 * Converts the context to an object of field:values
 * @param ctx {Object} An array of field values
 * @param fields {Array} A list of strings
 * @return {Object} Flattened Object in shape of { field: value, ... }
 */
const _getDeepValueObject = (ctx, fields) => fields.reduce((acc, field) => {
  // field names should  have number suffix starting from 1

  const suffix = field.match(/\d+/);
  if (suffix && suffix.length > 0) {
    const index = parseInt(suffix[0], 10);
    if (ctx.length >= index) {
      acc[field] = ctx[index - 1].value;
    }
  }
  return acc;
}, {});
/**
 * Gets all errors and maps them to the appropriate fields
 * @param ctx {Object} the Vue Instance which called this function
 * @param object {Object} An array of field values
 * @param fields {Array} A list of strings representing the form's properties
 * @return {Object}}
 */
const mapDeepErrors = (ctx, object, fields = []) => {
  const errors = _getDefaultErrorObject(fields);
  if (ctx?.lazy) return errors;
  try {
    const valueObject = _getDeepValueObject(object, fields);
    ctx.rules.validateSync(valueObject, { abortEarly: false, context: ctx });
  } catch (err) {
    Object.assign(errors, _mapMessagesToFields(fields, err.inner));
  }

  return errors;
};

/**
 * Determines if a form model is valid or not
 * @param errors {Object} Object returned from yup validateSync.inner
 * @return {Boolean}
 */
const objectIsValid = (errors) => !Object.keys(errors)
  .reduce((acc, error) => (errors[error].hasError || acc), false);

export {
  mapErrors,
  mapDeepErrors,
  objectIsValid,
};

const parsePhoneNumber = (num) => num.match(
  /^\s*[-.\\/]?\(?\s*(?<areaCode>\d{3})\s*\)?\s*[-.\\/]?(?<officeCode>\d{3})\s*[-.\\/]?(?<subscriber>\d{4})\s*[-.]?/,
)?.groups;
export const phoneNumberValidator = (num) => {
  const isValid = false;

  if (num.constructor.name !== 'String') return isValid;
  if (!num.length) return isValid;

  const { areaCode = null, officeCode = null, subscriber = null } = parsePhoneNumber(num) || {};
  const lineNumber = officeCode && subscriber && `${officeCode}${subscriber}`;
  if (areaCode && /[^01]11/.test(areaCode)) return isValid;
  if (areaCode && /[^01]9[0-9]/.test(areaCode)) return isValid;
  if (areaCode && !/[^01][^9][0-9]/.test(areaCode)) return isValid;
  if (officeCode && /[^01]11/.test(officeCode)) return isValid;
  if (officeCode && !/[^01][0-9]{2}/.test(officeCode)) return isValid;
  if (lineNumber && /555(?=1212|01\d{2}|4334)/.test(lineNumber)) return isValid;

  return true;
};

export const violationRange = () => Array(100).fill('').map((_value, index) => [
  'entertainment vanity numbers with office code 555 cannot be in range 0100-0199',
  `9015550${index + 100}`,
  false,
]);
